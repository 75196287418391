import Rest from '@/services/Rest';

/**
 * @typedef {ConsultDataUser}
 */
export default class ConsultDataUser extends Rest {
    /**
     * @type {String}
     */
    static resource = '/verify-data-user'
}
