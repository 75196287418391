<template>
<div style="margin-top: 80px">
    <BasePageBreadCrumb :pages="pages" title="novo síndico" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Nome do condomínio</label>
                                                <input type="text" class="form-control" v-model="liquidator.cond_name" v-validate="'required'" name="cond_name" :class="{'is-invalid': errors.has('cond_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>CNPJ</label>
                                                <input type="tel" class="form-control" v-model="liquidator.cnpj" v-mask="'##.###.###/####-##'" name="cnpj" :class="{'is-invalid': errors.has('cnpj')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Data de nascimento</label>
                                                <input type="date" class="form-control" placeholder="" v-model="liquidator.birth_date" name="birth_date" v-validate="'required'" :class="{'is-invalid': errors.has('birth_date')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Nome </label>
                                                <input type="text" class="form-control" v-model="liquidator.first_name" v-validate="'required'" name="first_name" :class="{'is-invalid': errors.has('first_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Sobrenome </label>
                                                <input type="text" class="form-control" v-model="liquidator.last_name" v-validate="'required'" name="last_name" :class="{'is-invalid': errors.has('last_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>CPF</label>
                                                <input type="text" @blur="verifyDataUser('cpf')" class="form-control" v-model="liquidator.cpf" v-validate="'required'" v-mask="'###.###.###-##'" name="cpf" :class="{'is-invalid': errors.has('cpf')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>E-mail </label>
                                                <input type="email" @blur="verifyDataUser('email')" class="form-control" placeholder="abc@example.com" v-model="liquidator.email" v-validate="'required|email'" name="email" :class="{'is-invalid': errors.has('email')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Whatsapp </label>
                                                <input type="tel" class="form-control" placeholder="(99) 9 9999-9999" v-model="liquidator.cellphone" v-validate="'required'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}" v-mask="['(##) ####-####', '(##) # ####-####']">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Sexo</label>
                                                <select class="custom-select mr-sm-2" v-model="liquidator.gender" v-validate="'required'" name="gender" :class="{'is-invalid': errors.has('gender')}">
                                                    <option :value="null">Selecione</option>
                                                    <option value="MALE">Masculino</option>
                                                    <option value="FEMALE">Feminino</option>
                                                    <option value="NONE">Não quero informar</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-model="liquidator.password" v-validate="passwordValidate" ref="password" name="password" :class="{'is-invalid': errors.has('password')}">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Confirmar senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="confirmPasswordValidate" name="confirmed" :class="{'is-invalid': errors.has('confirmed')}">
                                            </div>
                                            <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                        </div>
                                        <div class="col-12">
                                            <div class="card-list" v-for="(item, index) in liquidator.enterprises" :key="item.id">
                                                <div class="card-body-list">
                                                    <div class="col-12 p-0 align-self-center">
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                                                    <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                                                </div>
                                                                <div class="col-auto col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                                    <img v-if="item.image" :src="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                                                </div>
                                                                <div class="col-5 col-md-8 col-lg-9 p-0 order-3 align-self-center">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                                                <h6 class="text-truncate cod"><span>CÓD.:</span> {{ item.cod }}</h6>
                                                                                <h6 class="text-truncate card-body-list-title">{{ item.name }}</h6>
                                                                            </div>
                                                                            <div class="col-12 col-md-6 col-lg-3 align-self-center text-muted pad-content-list">
                                                                                <h6 class="text-truncate empreendi">Empreendimento</h6>
                                                                            </div>
                                                                            <div class="col-auto col-md-auto col-lg-1 align-self-center text-muted pad-content-list">
                                                                                <h6 class="text-truncate empreendi">{{ item.tower }}</h6>
                                                                            </div>
                                                                            <div class="col-auto col-md-auto col-lg-1 align-self-center text-muted pad-content-list">
                                                                                <h6 class="text-truncate empreendi">{{ item.ground_floor }}</h6>
                                                                            </div>
                                                                            <div class="col-auto col-md-auto col-lg-2 mx-auto align-self-center pad-content-list d-none d-md-block">
                                                                                <h6 class="text-truncate torre">{{ item.area }}</h6>
                                                                            </div>
                                                                            <div class="col-12 col-md-6 col-lg-2 align-self-center pad-content-list">
                                                                                <h6 class="status-imovel"><small class="badge form-text text-white" style="background-color: #563d7c;">Em construção</small></h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-2 col-lg-auto order-3 p-lg-3 p-0 ml-auto text-right align-self-center text-muted">
                                                                    <a class="btn btn-danger text-white rounded-circle btn-circle font-16" href="javascript:void(0);" @click="removeEnterprise(index)"><i class="far fa-trash-alt"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="liquidator.enterprises.length == 0" class="row justify-content-center">
                                                Sem imóveis
                                            </div>

                                            <div class="col-12 pt-4"></div>
                                            <div class="col-12 pb-2">
                                                <BaseEnterpriseBox v-model="enterprise_id" />
                                            </div>
                                            <div class="col-12 pt-3"></div>
                                            <div class="col-12 pb-2">
                                                <BaseAptBox v-model="floors" :enterprise_id="enterprise_id" />
                                            </div>

                                            <b-button type="button" class="btn btn-info" @click="addEnterprise">Adicionar</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn ml-2 btn-info">{{ id ? 'Atualizar' : 'Cadastrar'}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ClientService from "@/services/resources/ClientService";
import EnterpriseService from "@/services/resources/EnterpriseService";
import ConsultDataUserService from "@/services/resources/ConsultDataUser";
const consultService = ConsultDataUserService.build();

const service = ClientService.build();
const serviceEnterprise = EnterpriseService.build();

export default {
   data(){
       return{
           liquidator:{
               type: 'LIQUIDATOR',
               birth_date: null,
               gender: null,
               cond_name: null,
               first_name: null,
               last_name: null,
               cpf: null,
               cnpj: null,
               email: null,
               cellphone: null,
               enterprises: [],
               password: null
           },
           id: null,
           search: '',
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'assistẽncia técnica',
                    to: 'Dashboard'
                },
                {
                    name: 'novo síndico',
                    to: 'LiquidatorNew'
                }
            ],
            menu:[
                {
                    name: 'Novo chamado',
                    to: 'ReportNew'
                },
                {
                    name: 'Chamados',
                    to: 'Report'
                },
                {
                    name: 'Novo técnico',
                    to: 'TechnicalNew'
                },
                {
                    name: 'Técnicos',
                    to: 'Report'
                },
                {
                    name: 'Novo síndico',
                    to: 'LiquidatorNew'
                },
                {
                    name: 'Síndicos',
                    to: 'Liquidator'
                }
            ],
            enterprisesList: [],
            enterprise_id: null,
            floors: []
       }
   },
   computed:{
       enterprises(){
           return this.enterprisesList.filter(elem => {
               return elem.text.toLowerCase().includes(this.search.toLowerCase());
           });
       },
       passwordValidate(){
           return this.id ? 'min:8' : 'required|min:8';
       },
       confirmPasswordValidate(){
           return this.id ? 'confirmed:password' : 'required|confirmed:password';
       }
   },
   methods:{
     verifyDataUser(type){

            let data     = {};
            data['role_id'] = 3;//LIQUIDATOR
            
            if(type === 'cpf') {
  
                data['cpf'] = this.liquidator.cpf;
      
                consultService
                    .search(data)
                    .catch(err => {
                        this.$bvToast.toast('Cpf já utilizado para este tipo de usuário', {
                            title: 'Cadastro de síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })

            } else {

                    data['email'] = this.liquidator.email;
            
                     consultService
                    .search(data)
                    .catch(err => {
                        this.$bvToast.toast('E-mail já utilizado para este tipo de usuário', {
                            title: 'Cadastro de síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })

            }

       },
       save(){
        this.$validator.validateAll().then((result) => {
            if (result) {
                let liquidator = Object.assign(this.liquidator)
                if(this.id){
                    liquidator.id = this.id;

                    service
                    .update(liquidator)
                    .then(resp => {
                    this.$bvToast.toast('Seu síndico foi atualizado com sucesso!', {
                        title: 'Síndico atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }else{
                    service
                    .create(liquidator)
                    .then(resp => {
                    this.$bvToast.toast('Seu síndico foi criado com sucesso!', {
                        title: 'Síndico criado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }

          
            }
        });
       },
       removeEnterprise(index){
           this.$delete(this.liquidator.enterprises, index);
           this.$forceUpdate();
       },
       async addEnterprise(){
           if(!this.enterprise_id || this.floors.length == 0){
               this.$bvToast.toast('Selecione um imóvel e pelo menos um apartamento.', {
                    title: 'Adicionar imóvel',
                    autoHideDelay: 5000,
                    type: 'danger'
                })
               return;
           }
            let enterprise = await serviceEnterprise.read({id: this.enterprise_id}).then();
            enterprise.floors = this.floors;
            this.floors = [];
            this.enterprise_id = null;
            this.liquidator.enterprises.push(enterprise);
       },
       async fetchEnterprise(){
            return serviceEnterprise.search().then();
       },
       fetchLiquidator(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        }

        service
        .read(data)
        .then(resp => {
            this.liquidator = resp;
            this.liquidator.birth_date = resp.client.birth_date;
            this.liquidator.gender = resp.client.gender;
            this.$validator.reset();
        })
        .catch(err => {
        console.log(err)
        })

       }
   },
   async mounted(){
        this.id = this.$route.params.id;
        this.fetchLiquidator();

        var resp = await this.fetchEnterprise();
        this.enterprisesList = this.enterprises.concat(resp.map(elem => {
            return {
                value: elem.id,
                text: elem.name
            }
        }))
   }
}
</script>